html, body {
  margin: 0;
  padding: 0;
  height: 100dvh;
  overflow: hidden;
}

.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-image: url('../public/assets/img/eafootball-bg-welcome.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100dvh;
  height: 100dvh;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.7;
}

.App > * {
  position: relative;
  z-index: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  box-sizing: border-box;
}

.logo-container img {
  max-width: 45%;
  height: auto;
  animation: logoGlow 2s ease-in-out infinite;
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
}

.logo-container div img {
  width: 60vw;
  max-width: 60vw;
}

img {
  max-width: 100%;
  height: auto;
}

.lead {
  font-family: 'Anonymous Pro', monospace;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 2rem;
  opacity: 1;
  color: white;
  background-color: #0066cc;
  display: inline-block;
  padding: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.pacman-container {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 15px;
  width: 300px;
  height: 50px;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.pacman {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  animation: pacman-move 4s linear forwards;
  z-index: 2;
}

.pacman > div:nth-child(1),
.pacman > div:nth-child(2) {
  width: 0;
  height: 0;
  border: 15px solid #0066cc;
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: rotate_pacman_half_up 0.5s infinite;
}

.pacman > div:nth-child(2) {
  animation-name: rotate_pacman_half_down;
  margin-top: -30px;
}

.dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  right: 20px;
  height: 6px;
}

.dot {
  width: 8px;
  height: 8px;
  background-image: url('../public/assets/img/ball-pill-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  opacity: 1;
  transition: opacity 0.1s ease-out;
}

.dot:nth-child(1) { left: 0px; }
.dot:nth-child(2) { left: 25px; }
.dot:nth-child(3) { left: 50px; }
.dot:nth-child(4) { left: 75px; }
.dot:nth-child(5) { left: 100px; }
.dot:nth-child(6) { left: 125px; }
.dot:nth-child(7) { left: 150px; }
.dot:nth-child(8) { left: 175px; }
.dot:nth-child(9) { left: 200px; }
.dot:nth-child(10) { left: 225px; }

@keyframes rotate_pacman_half_up {
  0% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotate_pacman_half_down {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8));
  }
  100% {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
  }
}

@keyframes pacman-move {
  0% {
    left: -30px;
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: 300px;
    opacity: 0;
  }
}

.pacman-eye {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: 5px;
  right: 13px;
  z-index: 3;
}

.player-text {
  font-family: 'VT323', monospace;
  color: white;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 4;
  white-space: nowrap;
  animation: textAppear 0.5s ease-out 4s forwards,
             shake 0.5s ease-in-out 4.5s;
}

@keyframes textAppear {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes shake {
  0%, 100% { transform: translate(-50%, -50%) rotate(0deg); }
  25% { transform: translate(-50%, -50%) rotate(-5deg); }
  75% { transform: translate(-50%, -50%) rotate(5deg); }
}

@keyframes logoGlow {
  0% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.8));
  }
  100% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }
}

@media (max-width: 768px) {
  .logo-container {
    padding: 1rem 0;
  }

  .logo-container img {
    max-width: 50%;
  }

  .logo-container div img {
    width: 70vw;
    max-width: 70vw;
  }

  .pacman-container {
    width: 280px;
    margin-bottom: 1rem;
  }

  /* Hide the last dot on mobile */
  .dot:nth-child(10) {
    display: none;
  }

  /* Adjust Pacman's final position for 9 dots */
  @keyframes pacman-move {
    0% {
      left: -30px;
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      left: 275px;
      opacity: 0;
    }
  }
}

.powered-by {
  margin-top:  0px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 0.5s ease-out forwards;
}

@keyframes fadeUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.powered-text {
  font-family: 'Anonymous Pro', monospace;
  font-size: 1rem;
  color: white;
  margin: 0 0 0.5rem 0;
  font-style: italic;
}

.sponsor-logo {
  display: inline-block;
  width: 120px !important;
  height: auto !important;
  max-width: 120px !important;
  object-fit: contain;
  border: 5px solid #107C10;
  border-radius: 4px;
  padding: 8px;
  background: white;
}

@media (max-width: 768px) {
  .sponsor-logo {
    width: 100px !important;
    max-width: 100px !important;
  }
}
